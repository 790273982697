import { WorkflowStepType } from '@frontline/common';

export const stepRouteParams = {
  [WorkflowStepType.AddOns]: '/add-ons',
  [WorkflowStepType.AdditionalDetails]: '/additional-details',
  [WorkflowStepType.Applicant]: '/applicant',
  [WorkflowStepType.BankDocuments]: '/bank-documents',
  [WorkflowStepType.CoApplicant]: '/co-applicant',
  [WorkflowStepType.CoApplicantPersonalDetails]: '/personal-details',
  [WorkflowStepType.CoApplicantIncomeDetails]: '/income-details',
  [WorkflowStepType.CoApplicantAdditionalDetails]: '/additional-details',
  [WorkflowStepType.AssetInformation]: '/asset-information',
  [WorkflowStepType.IncomeDetails]: '/income-details',
  [WorkflowStepType.LoanDetails]: '/loan-details',
  [WorkflowStepType.LoanAndFinancingDetails]: '/loan-details',
  [WorkflowStepType.PaymentDetails]: '/add-payment-details',
  [WorkflowStepType.Flinks]: '/flinks',
  [WorkflowStepType.PersonalDetails]: '/personal-details',
  [WorkflowStepType.PurchaseDetails]: '/purchase-details',
  [WorkflowStepType.Review]: '/review',
  [WorkflowStepType.SignDocuments]: '/sign-loan-documents',
  [WorkflowStepType.UploadDocuments]: '/upload-documents',
  [WorkflowStepType.VerifyIdentity]: '/verify-identity',
  [WorkflowStepType.LoanProtection]: '/loan-protection',
  [WorkflowStepType.ComponentSelector]: '/component-selector',
  [WorkflowStepType.InvoiceDetails]: '/invoice-details',
  [WorkflowStepType.PreAuthorizedDebit]: '/pre-authorized-debit',
};
