import { defineMessages } from 'react-intl';

export const workflowMessages = defineMessages({
  purchaseDetails: {
    id: 'Applicant.PostSubmission.Common.purchaseDetails',
    description: 'Purchase details Step Description',
    defaultMessage: 'Purchase details',
  },
  applicant: {
    id: 'Applicant.PostSubmission.Common.applicant',
    description: 'Applicant Workflow Step Description',
    defaultMessage: 'Finalize {applicantName}’s Details',
  },
  bankDocuments: {
    id: 'Applicant.PostSubmission.Common.bankDocuments',
    description: 'Bank Documents Workflow Step Description',
    defaultMessage: 'Upload Bank Transactions',
  },
  coApplicant: {
    id: 'Applicant.PostSubmission.Common.coApplicant',
    description: 'Co-Applicant Workflow Step Description',
    defaultMessage: 'Finalize {coApplicantName}’s Details',
  },
  personalDetails: {
    id: 'Applicant.PostSubmission.Common.personalDetails',
    description: 'Personal Details Workflow Step Description',
    defaultMessage: 'Personal details',
  },
  incomeDetails: {
    id: 'Applicant.PostSubmission.Common.incomeDetails',
    description: 'Income details Workflow Step Description',
    defaultMessage: 'Income details',
  },
  additionalDetails: {
    id: 'Applicant.PostSubmission.Common.additionalDetails',
    description: 'Additional details Workflow Step Description',
    defaultMessage: 'Additional details',
  },
  assetInformation: {
    id: 'Applicant.PostSubmission.Common.assetInformation',
    description: 'Asset Details Workflow Step Description',
    defaultMessage: 'Finalize Asset Details',
  },
  loanDetails: {
    id: 'Applicant.PostSubmission.Common.loanDetails',
    description: 'Loan Details Workflow Step Description',
    defaultMessage: 'Finalize Payment Plan',
  },
  finalizePaymentPlan: {
    id: 'Applicant.PostSubmission.Common.finalizePaymentPlan',
    description: 'Finalize Loan Details Workflow Step Description',
    defaultMessage: 'Finalize Payment Plan',
  },
  addOns: {
    id: 'Applicant.PostSubmission.Common.addOns',
    description: 'Add-ons Workflow Stepe Description',
    defaultMessage: 'Select Add-ons',
  },
  signDocuments: {
    id: 'Applicant.PostSubmission.Common.signDocuments',
    description: 'Sign Documents Workflow Step Description',
    defaultMessage: 'Sign Loan Documents',
  },
  paymentDetails: {
    id: 'Applicant.PostSubmission.Common.paymentDetails',
    description: 'Payment Details Workflow Step Description',
    defaultMessage: 'Set Up Payment Details',
  },
  uploadDocuments: {
    id: 'Applicant.PostSubmission.Common.uploadDocuments',
    description: 'Upload Documents Workflow Step Description',
    defaultMessage: 'Upload Documents',
  },
  verifyIdentity: {
    id: 'Applicant.PostSubmission.Common.verifyIdentity',
    defaultMessage: 'Verify Your Identity',
  },
  loanProtection: {
    id: 'Applicant.PostSubmission.Common.loanProtection',
    description: 'Loan Protection Workflow Step Description',
    defaultMessage: 'Add loan protection',
  },
  invoiceDetails: {
    id: 'Applicant.PostSubmission.Common.invoiceDetails',
    description: 'Invoice Details Workflow Step Description',
    defaultMessage: 'Invoice Details',
  },
  confirmInvoiceDetails: {
    id: 'Applicant.PostSubmission.Common.confirmInvoiceDetails',
    defaultMessage: 'Confirm Invoice Details',
  },
  preAuthorizedDebits: {
    id: 'Applicant.PostSubmission.Common.preAuthorizedDebits',
    description: 'Pre-authorized debits tab title',
  },
  unknown: {
    id: 'Applicant.PostSubmission.Common.unknown',
    description: 'Unknown Workflow Step Description',
    defaultMessage: 'N/A',
  },
});
