import {
  isApplicantOutOfAttempts,
  isIdentityVerificationCompleteAndValid,
} from '@frontline/common';
import {
  disableVerifyIdentityContinueButton,
  isVerifyIdentityStateError,
  shouldGetVerifyIdentityUrl,
  VerifyIdentityView,
} from '@frontline/web-common';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as postSubmissionReviewStore from '../../../../../../store/post-submission.store';
import { PostSubmissionContentCard } from '../../../../components/post-submission-content-card';
import * as store from '../../store/verifyIdentity.store';
import { getMessageDefinitionForVerifyIdentity } from '../../types/getMessageDefinitionForVerifyIdentity.functions';

const VerifyIdentity: React.FC = () => {
  const dispatch = useDispatch();

  const applicant = useSelector(store.getApplicant);
  const isLoading = useSelector(store.isLoading);
  const netverifyState = useSelector(store.getNetverify);
  const verificationMessages = useSelector(store.getVerificationMessages);
  const updatesEnabled = useSelector(store.updatesEnabled);

  const maxNumberOfNetverifyAttempts = process.env.NETVERIFY_MAX_TRIES
    ? +process.env.NETVERIFY_MAX_TRIES
    : 2;

  if (
    shouldGetVerifyIdentityUrl(
      isLoading,
      updatesEnabled,
      applicant?.netverifyAttempts,
      maxNumberOfNetverifyAttempts,
      netverifyState,
      verificationMessages,
    )
  ) {
    store.getNetverifyUrl(dispatch)();
  }

  useEffect(() => {
    if (isVerifyIdentityStateError(netverifyState)) {
      store.getNetverifyUrl(dispatch)();
    }
  }, [dispatch, netverifyState]);

  return (
    <PostSubmissionContentCard>
      <VerifyIdentityView
        hasRedirectUrl={Boolean(netverifyState?.redirectUrl)}
        outOfAttempts={isApplicantOutOfAttempts(
          applicant?.netverifyAttempts,
          maxNumberOfNetverifyAttempts,
        )}
        numOfAttempts={applicant?.netverifyAttempts || 0}
        verified={isIdentityVerificationCompleteAndValid(
          netverifyState,
          verificationMessages,
        )}
        netverify={netverifyState!}
        updatesDisabled={!updatesEnabled}
        firstName={useSelector(store.getApplicantFirstName) || ''}
        getMessageDefinitionForVerifyIdentity={
          getMessageDefinitionForVerifyIdentity
        }
        onEnd={store.setNetverifyStatus(dispatch)}
        toPrevStep={postSubmissionReviewStore.navigateToPreviousStep(dispatch)}
        toNextStep={postSubmissionReviewStore.navigateToNextStep(dispatch)}
        disableContinue={disableVerifyIdentityContinueButton(netverifyState)}
        verifyIdentityState={useSelector(
          postSubmissionReviewStore.getVerifyIdentityState,
        )}
        canResetStep={false}
      />
    </PostSubmissionContentCard>
  );
};

export default VerifyIdentity;
