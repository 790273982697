import {
  Application,
  ComponentSelectorStep,
  createComponentChoiceWorkflowStepsWithSelectedStepInfo,
  getActiveWorkflowSteps,
  ProgramType,
  WorkflowStep,
  WorkflowStepType,
} from '@frontline/common';
import { MessageDescriptor } from 'react-intl';
import { createPostSubmissionReviewPath } from './PostSubmissionReviewUrl.functions';
import { workflowMessages } from './PostSubmissionSteps.messages';
import { StepMessageDefinitionOptions } from './StepMessageDefinitionOptions';
import { createStepMessageDefinitionOptions } from './StepMessageDefinitionOptions.functions';

export const createPostSubmissionWorklowSteps = (
  workflowSteps: WorkflowStep[],
  application?: Application,
): WorkflowStep[] => {
  return getActiveWorkflowSteps(
    getPostSubmissionStepsWithRoutes(workflowSteps, application?.id),
    application,
  );
};

const getPostSubmissionStepsWithRoutes = (
  steps: WorkflowStep[],
  applicationId?: string,
): WorkflowStep[] => {
  if (!applicationId) {
    return [];
  }

  return steps.map(step => {
    step.routeUrl = createPostSubmissionReviewPath(step.type, applicationId);
    if (step.type === WorkflowStepType.ComponentSelector) {
      step.routeUrl += `/${step.id}`;
      createComponentChoiceWorkflowStepsWithSelectedStepInfo(
        step as ComponentSelectorStep,
      ).forEach((choiceStep: WorkflowStep) => {
        choiceStep.routeUrl = createRouteForComponentSelectorChoiceStep(
          choiceStep,
          applicationId,
        );
      });
    }
    if (step.subSteps && step.subSteps.length) {
      const newSubSteps = getPostSubmissionStepsWithRoutes(
        step.subSteps,
        applicationId,
      );
      step.subSteps = newSubSteps || [];
    }
    return step;
  });
};

const createRouteForComponentSelectorChoiceStep = (
  choiceStep: WorkflowStep,
  applicationId: string,
) => {
  const reviewPagePath = createPostSubmissionReviewPath(
    choiceStep.type,
    applicationId,
  );
  return `${reviewPagePath}/${choiceStep.id}`;
};

export const getStepMessageDefinition = (
  stepType: WorkflowStepType,
  programType?: ProgramType,
): MessageDescriptor => {
  return getStepMessageDefinitionByStepType(
    stepType,
    createStepMessageDefinitionOptions(programType),
  );
};

const getStepMessageDefinitionByStepType = (
  stepType: WorkflowStepType,
  options: StepMessageDefinitionOptions = {},
): MessageDescriptor => {
  switch (stepType) {
    case WorkflowStepType.CoApplicant:
      return workflowMessages.coApplicant;
    case WorkflowStepType.Applicant:
      return workflowMessages.applicant;
    case WorkflowStepType.AssetInformation:
      return workflowMessages.assetInformation;
    case WorkflowStepType.LoanAndFinancingDetails:
      return options?.finalizePaymentPlan
        ? workflowMessages.finalizePaymentPlan
        : workflowMessages.loanDetails;
    case WorkflowStepType.AddOns:
      return workflowMessages.addOns;
    case WorkflowStepType.SignDocuments:
      return workflowMessages.signDocuments;
    case WorkflowStepType.Flinks:
    case WorkflowStepType.PaymentDetails:
      return workflowMessages.paymentDetails;
    case WorkflowStepType.PreAuthorizedDebit:
      return workflowMessages.preAuthorizedDebits;
    case WorkflowStepType.UploadDocuments:
      return workflowMessages.uploadDocuments;
    case WorkflowStepType.PersonalDetails:
    case WorkflowStepType.CoApplicantPersonalDetails:
      return workflowMessages.personalDetails;
    case WorkflowStepType.IncomeDetails:
    case WorkflowStepType.CoApplicantIncomeDetails:
      return workflowMessages.incomeDetails;
    case WorkflowStepType.AdditionalDetails:
    case WorkflowStepType.CoApplicantAdditionalDetails:
      return workflowMessages.additionalDetails;
    case WorkflowStepType.VerifyIdentity:
      return workflowMessages.verifyIdentity;
    case WorkflowStepType.LoanProtection:
      return workflowMessages.loanProtection;
    case WorkflowStepType.InvoiceDetails:
      return options?.confirmInvoiceDetails
        ? workflowMessages.confirmInvoiceDetails
        : workflowMessages.invoiceDetails;
    case WorkflowStepType.BankDocuments:
      return workflowMessages.bankDocuments;
    default:
      return workflowMessages.unknown;
  }
};
