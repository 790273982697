import React, { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Overview from './features/Overview';
import { PostSubmissionReviewFeature } from './features/PostSubmissionReview';
import { postSubmissionPath } from './types';

export interface PostSubmissionRoutesProps {
  applicationId?: string;
}

const PostSubmissionRoutes: FunctionComponent<PostSubmissionRoutesProps> = (
  props: PostSubmissionRoutesProps,
) => {
  if (!props?.applicationId) {
    return null;
  }
  return (
    <Switch>
      <Route
        path={postSubmissionPath('overview', props.applicationId)}
        component={Overview}
      />
      <Route
        path={postSubmissionPath('review', props.applicationId)}
        component={PostSubmissionReviewFeature}
      />
      <Redirect to={postSubmissionPath('overview', props.applicationId)} />}
    </Switch>
  );
};

export default PostSubmissionRoutes;
