// TODO: Deprecate this file. Should transition code to directly use web-common
import { actions } from '@frontline/web-common';

export const {
  addCoApplicant,
  removeCoApplicant,
  removeCoApplicantSuccess,
  deleteCoApplicant,
  deleteCoApplicantSuccess,
  deleteCoApplicantError,
  dismissAddCoApplicant,
  addCoApplicantConfirmed,
  addCoApplicantCancelled,
  addPostSubmissionCoApplicantConfirmed,
  addPostSubmissionCoApplicantCancelled,
  removeCoApplicantConfirmed,
  loadApplication,
  loadApplicationSuccess,
  loadApplicationError,
  navigateToNextPostSubmissionStep,
  navigateToPreviousPostSubmissionStep,
  openCoApplicantDialog,
  closeCoApplicantDialog,
  openRemoveCoApplicantDialog,
  closeRemoveCoApplicantDialog,
  createApplication,
  createApplicationSuccess,
  createApplicationError,
  goToOverview,
  goToPostSubmissionStepBefore,
  goToPostSubmissionStepAfter,
  saveApplicantDetailsFastFill,
  saveApplicantPersonalDetails,
  saveApplicantPersonalDetailsSuccess,
  saveApplicantPersonalDetailsError,
  saveApplicantIncomeDetails,
  saveApplicantIncomeDetailsSuccess,
  saveApplicantIncomeDetailsError,
  saveApplicantAdditionalDetails,
  saveApplicantAdditionalDetailsSuccess,
  saveApplicantAdditionalDetailsError,
  saveCoApplicant,
  saveCoApplicantSuccess,
  saveCoApplicantError,
  saveCoApplicantPersonalDetails,
  saveCoApplicantPersonalDetailsSuccess,
  saveCoApplicantPersonalDetailsError,
  saveCoApplicantIncomeDetails,
  saveCoApplicantIncomeDetailsSuccess,
  saveCoApplicantIncomeDetailsError,
  saveCoApplicantAdditionalDetails,
  saveCoApplicantAdditionalDetailsSuccess,
  saveCoApplicantAdditionalDetailsError,
  saveLoanDetails,
  saveLoanDetailsSuccess,
  saveLoanDetailsError,
  savePreSubmissionStep,
  savePreSubmissionStepSuccess,
  savePreSubmissionStepError,
  savePostSubmissionStep,
  savePostSubmissionStepSuccess,
  savePostSubmissionStepError,
  savePaymentDetails,
  savePaymentDetailsSuccess,
  savePaymentDetailsError,
  setCurrentApplication,
  submitApplication,
  submitApplicationSuccess,
  submitApplicationError,
  submitForRescoring,
  submitForRescoringSuccess,
  submitForRescoringError,
  submitForFunding,
  submitForFundingSuccess,
  submitForFundingError,
  uploadDocument,
  uploadDocumentSuccess,
  uploadDocumentError,
  attachPendingDocuments,
  attachPendingDocumentsSuccess,
  attachPendingDocumentsError,
  deleteDocument,
  deleteDocumentSuccess,
  deleteDocumentError,
  refreshApplication,
  refreshApplicationSuccess,
  refreshApplicationError,
  updateApplication,
  updateSignDocuments,
  updateSignDocument,
  updateAccountDetailsWithFlinks,
  getNetverifyUrl,
  setNetverifyStatus,
  saveWorkflowDecisionSuccess,
  saveWorkflowDecision,
} = actions.applicationActions;
