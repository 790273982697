import {
  isIdentityVerificationCompleteAndValid,
  WorkflowStepStatus,
} from '@frontline/common';

import React from 'react';

import {
  PrimaryButton,
  SecondaryButton,
  ShowIfFragment,
} from '@frontline/ui-library';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { OverViewStepMenuPageProps } from '../overview-step-menu-page.props';

import * as applicationStore from '../../../../../../../../store/application.store';
import * as postSubmissionStore from '../../../../../../store/post-submission.store';

import messages from './verify-identity-step.messages';

import OverviewStepActions from '../OverviewStepMenuPageGeneric/overview-step-actions.component';
import OverviewStepDescription from '../OverviewStepMenuPageGeneric/overview-step-description.component';
import OverviewStepTitle from '../OverviewStepMenuPageGeneric/overview-step-title.component';

import { useSelector } from 'react-redux';
import { useVerifyIdentityStepStyles } from './verify-identity-step.styles';

export const VerifyIdentityStep = (props: OverViewStepMenuPageProps) => {
  const classes = useVerifyIdentityStepStyles();
  const applicant = useSelector(applicationStore.getApplicant);
  const application = useSelector(applicationStore.getApplication);
  const updatesEnabled = useSelector(
    postSubmissionStore.areApplicantUpdatesEnabled,
  );

  const netverify = application?.netverify;

  if (!applicant) {
    return <FormattedMessage {...messages.error} />;
  }

  const attempts = applicant.netverifyAttempts || 0;

  const NETVERIFY_MAX_TRIES = process.env.NETVERIFY_MAX_TRIES
    ? +process.env.NETVERIFY_MAX_TRIES
    : 2;

  const getMessage = () => {
    if (
      isIdentityVerificationCompleteAndValid(
        netverify,
        application?.verificationMessages,
      )
    ) {
      return <FormattedMessage {...messages.success} />;
    }

    if (
      applicant &&
      applicant.netverifyAttempts &&
      applicant.netverifyAttempts >= NETVERIFY_MAX_TRIES
    ) {
      return <FormattedMessage {...messages.outOfAttempts} />;
    }

    if (
      applicant &&
      applicant.netverifyAttempts &&
      applicant.netverifyAttempts > 0
    ) {
      return <FormattedMessage {...messages.failedAttempts} />;
    }

    return '';
  };

  const getEditButton = () => {
    if (attempts < NETVERIFY_MAX_TRIES) {
      return (
        <Link to={props.routeUrl || ''} {...props.uat}>
          <ShowIfFragment
            showIf={props.status !== WorkflowStepStatus.Completed}>
            <PrimaryButton disabled={!updatesEnabled}>
              <FormattedMessage {...messages.editButton} />
            </PrimaryButton>
          </ShowIfFragment>
          <ShowIfFragment
            showIf={props.status === WorkflowStepStatus.Completed}>
            <SecondaryButton disabled={!updatesEnabled}>
              <FormattedMessage {...messages.editButton} />
            </SecondaryButton>
          </ShowIfFragment>
        </Link>
      );
    }
    return '';
  };

  return (
    <div className={classes.step}>
      <OverviewStepTitle>
        <FormattedMessage {...messages.title} />
      </OverviewStepTitle>
      <OverviewStepDescription>
        <FormattedMessage {...messages.description} />
      </OverviewStepDescription>
      <OverviewStepActions>
        {getMessage()}
        {getEditButton()}
      </OverviewStepActions>
    </div>
  );
};
