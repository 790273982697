import {
  findWorkflowStepByType,
  WorkflowStep,
  WorkflowStepType,
} from '@frontline/common';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { createPostSubmissionReviewPath } from '../../types';
import { ApplicantDetailsFeature } from './features/ApplicantDetails/ApplicantDetailsFeature';
import AssetInformation from './features/AssetInformation/components/AssetInformation/AssetInformation';
import { BankDocumentsFeature } from './features/bank-documents/bank-documents-feature.component';
import { CoApplicantDetailsFeature } from './features/CoApplicantDetails';
import ComponentSelector from './features/ComponentSelector/components/ComponentSelector/ComponentSelector';
import { Flinks } from './features/Flinks/components/Flinks';
import { InvoiceDetailsFeature } from './features/InvoiceDetails/InvoiceDetailsFeature';
import { LoanDetails } from './features/loan-details/components/loan-details/loan-details.component';
import { LoanProtectionFeature } from './features/loan-protection';
import { PaymentDetails } from './features/payment-details';
import { PreAuthorizedDebitFeature } from './features/pre-authorized-debit/pre-authorized-debit.feature';
import { SignLoanDocuments } from './features/sign-loan-documents/components/sign-loan-documents/sign-loan-documents.component';
import { ThirdPartyAddOnsFeature } from './features/ThirdPartyAddOns/ThirdPartyAddOnsFeature';
import { UploadDocuments } from './features/UploadDocuments';
import VerifyIdentity from './features/verify-identity/components/verify-identity/verify-identity.component';

interface PostSubmissionReviewRoutesProps {
  steps: WorkflowStep[];
  applicationId: string;
}

export const PostSubmissionReviewRoutes = (
  props: PostSubmissionReviewRoutesProps,
) => {
  const createPath = (stepType: WorkflowStepType) =>
    createPostSubmissionReviewPath(stepType, props.applicationId);
  const findStep = (stepType: WorkflowStepType) =>
    findWorkflowStepByType(props.steps, stepType);
  const applicantStep = findStep(WorkflowStepType.Applicant);
  const coApplicantStep = findStep(WorkflowStepType.CoApplicant);
  const signLoanDocumentsStep = findStep(WorkflowStepType.SignDocuments);
  return (
    <Switch>
      <Route
        path={createPath(WorkflowStepType.AddOns)}
        render={() => <ThirdPartyAddOnsFeature />}
      />
      <Route
        path={createPath(WorkflowStepType.Applicant)}
        render={() => (
          <ApplicantDetailsFeature
            applicationId={props.applicationId}
            steps={applicantStep?.subSteps || []}
          />
        )}
      />
      <Route
        path={createPath(WorkflowStepType.AssetInformation)}
        render={() => <AssetInformation />}
      />{' '}
      <Route
        path={createPath(WorkflowStepType.BankDocuments)}
        render={() => <BankDocumentsFeature />}
      />
      <Route
        path={createPath(WorkflowStepType.CoApplicant)}
        render={() => (
          <CoApplicantDetailsFeature
            applicationId={props.applicationId}
            steps={coApplicantStep?.subSteps || []}
          />
        )}
      />
      <Route
        path={createPath(WorkflowStepType.LoanDetails)}
        render={() => <LoanDetails />}
      />
      <Route
        path={createPath(WorkflowStepType.LoanProtection)}
        render={() => <LoanProtectionFeature />}
      />
      <Route
        path={createPath(WorkflowStepType.PaymentDetails)}
        render={() => <PaymentDetails />}
      />
      <Route
        path={createPath(WorkflowStepType.SignDocuments)}
        render={() =>
          signLoanDocumentsStep && (
            <SignLoanDocuments
              applicationId={props.applicationId}
              step={signLoanDocumentsStep}
            />
          )
        }
      />
      <Route
        path={createPath(WorkflowStepType.Flinks)}
        render={() => <Flinks />}
      />
      <Route
        path={createPath(WorkflowStepType.UploadDocuments)}
        render={() => <UploadDocuments />}
      />
      <Route
        path={createPath(WorkflowStepType.VerifyIdentity)}
        render={() => <VerifyIdentity />}
      />
      <Route
        path={`${createPath(WorkflowStepType.ComponentSelector)}`}
        render={() => <ComponentSelector />}
      />
      <Route
        path={createPath(WorkflowStepType.InvoiceDetails)}
        render={() => <InvoiceDetailsFeature />}
      />
      <Route
        path={createPath(WorkflowStepType.PreAuthorizedDebit)}
        render={() => <PreAuthorizedDebitFeature />}
      />
    </Switch>
  );
};
