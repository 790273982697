import {
  flinksViewEpics,
  invoiceDetailsEpics as sharedInvoiceDetailsEpics,
  preAuthorizedDebitEpics,
  verifyIdentityEpics,
} from '@frontline/web-common';
import assetInformationEpics from '../features/AssetInformation/store/assetInformation.epics';
import bankDocumentsEpics from '../features/bank-documents/store/bank-documents.epics';
import componentSelectorEpics from '../features/ComponentSelector/store/componentSelector.epics';
import invoiceDetailsEpics from '../features/InvoiceDetails/store/invoiceDetails.epics';
import loanDetailsEpics from '../features/loan-details/store/loan-details.epics';
import loanProtectionEpics from '../features/loan-protection/store/loan-protection.epics';
import signDocumentsEpics from '../features/sign-loan-documents/store/sign-documents.epics';
import thirdPartyAddOnsEpics from '../features/ThirdPartyAddOns/store/thirdPartyAddOns.epic';

export default [
  ...componentSelectorEpics,
  ...loanProtectionEpics,
  ...assetInformationEpics,
  ...loanDetailsEpics,
  ...signDocumentsEpics,
  ...thirdPartyAddOnsEpics,
  ...invoiceDetailsEpics,
  ...sharedInvoiceDetailsEpics,
  ...bankDocumentsEpics,
  ...preAuthorizedDebitEpics,
  ...verifyIdentityEpics,
  ...flinksViewEpics,
];
